<template>
    <el-dialog :title="'详情'" :close-on-click-modal="false" :modal-append-to-body="false" :lock-scroll="false"
        :visible.sync="visible">
        <div class="material-view">
            <el-form :modal-append-to-body="false" label-width="80px">
                <el-form-item label="需求反馈:" label-width=15%>
                    {{ feedback }}
                </el-form-item>
                <el-form-item label="备注:" label-width=15%>
                    {{ note }}
                </el-form-item>
                <el-form-item label="处理人:" label-width=15%>
                    {{ sysUserName }}
                </el-form-item>
                <el-form-item label="处理内容:" label-width=15%>
                    <el-input type="textarea" :rows="2" v-model="proContext" readonly>
                    </el-input>
                </el-form-item>
                <el-form-item label="处理时间:" label-width=15%>
                    {{ formatOroDate }}
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false" type="primary">关闭</el-button>
        </span>
</el-dialog>
</template>

<script>

import moment from 'moment'

export default {
    data() {
        return {
            feedback: '',
            note: '',
            sysUserName: '',
            proContext: '',
            formatOroDate: '',
            visible: false,
            loading: false,
            oliTuserComplaintInfo: {}
        }
    },
    methods: {
        init(row) {
            this.feedback = row.feedback
            this.note = row.note
            this.sysUserName = row.sysUserName
            this.proContext = row.proContext
            this.formatOroDate = row.formatOroDate
            this.visible = true
        },
    }
}
</script>

<style scoped></style>
