<template>
    <div class="box">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
            <table class="detailTable_search" style="width: 100%">
                <tr>
                    <td class="titleTd width80px">日期</td>
                    <td class="valueTd">
                        <div class="block">
                            <el-date-picker v-model="dataForm.time" type="daterange" align="right" unlink-panels
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                @change="changeHandler" :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </td>
                    <td class="titleTd width80px">处理状态</td>
                    <td class="valueTd" prop="stateValue">
                        <el-select v-model="dataForm.stateValue" placeholder="请选择" @change="getDayStateValue" clearable>
                            <el-option v-for="item in dayStates" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </td>

                    <td class="titleTd width80px">投诉等级</td>
                    <td class="valueTd" prop="grade">
                        <el-select v-model="dataForm.grade" placeholder="请选择" @change="getDayStateValue" clearable>
                            <el-option v-for="item in daygrade" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </td>

                    <td class="titleTd width80px">区域</td>
                    <td class="valueTd">
                        <region-tree :rootId="gridId !== '' ? String(gridId) : String(regionId)" placeholder="请选择区域"
                            v-model="dataForm.regionId"> </region-tree>
                    </td>
                </tr>
            </table>
        </el-form>
        <div class="wxts_msg_search">
            <div class="f_t">
                <el-button-group>
                    <el-button size="mini" @click="search()" type="success" :disabled="dataListLoading">查询</el-button>
                    <el-button size="mini" type="danger" @click="assignedComplaint()" :disabled="dataListSelections.length <= 0">
                        批量指派
                    </el-button>
                </el-button-group>
            </div>
        </div>
        <el-table :data="dataList" border stripe v-loading="dataListLoading" @selection-change="selectionChangeHandle"
            style="width: 100%;">
            <el-table-column w type="selection" header-align="center" align="center" width="50px">
            </el-table-column>
            <el-table-column prop="feedback" header-align="center" align="center" label="投诉反馈"  width="100px">
            </el-table-column>
            <el-table-column prop="note" header-align="center" align="center" label="投诉内容" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="name" header-align="center" align="center" label="投诉人"  width="100px">
            </el-table-column>
            <el-table-column prop="area" header-align="center" align="center" label="投诉区域"  width="150px">
                <template slot-scope="scope">
                    <org :value="scope.row.area" />
                </template>
            </el-table-column>
            <el-table-column prop="createDate" header-align="center" align="center" label="投诉时间"
                :formatter="gridDateFormatter"  width="150px">
            </el-table-column>
            <el-table-column prop="contactWay" header-align="center" align="center" label="联系方式"  width="100px">
            </el-table-column>
            <el-table-column prop="state" header-align="center" align="center" label="处理状态"  width="100px">
                <template slot-scope="scope">
                    <span v-if="scope.row.state == '0'">未处理</span>
                    <span v-if="scope.row.state == '1'">已处理</span>
                    <span v-if="scope.row.state == '2'">已下发</span>
                </template>
            </el-table-column>
            <el-table-column prop="createType" header-align="center" align="center" label="投诉渠道"  width="100px">
                <template slot-scope="scope">
                    <span v-if="scope.row.createType == '1'">微信投诉</span>
                    <span v-if="scope.row.createType == '2'">网格经理</span>
                </template>
            </el-table-column>
            <el-table-column prop="grade" header-align="center" align="center" label="投诉等级"  width="100px">
                <template slot-scope="scope">
                    <span v-if="scope.row.grade == '1'">一般</span>
                    <span v-if="scope.row.grade == '2'">严重</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" align="center" label="操作"  width="150px">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.grade === '1'" class="update-button" type="text" size="small"
                        @click="assignedComplaint(scope.row.id)">
                        指派投诉</el-button>
                    <el-button class="update-button" type="text" size="small" @click="detailInfo(scope.row)">
                        详情</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper"></el-pagination>

        <assigned v-if="assignedVisible" ref="assigned" @refreshDataList="getDataList"></assigned>

        <!-- 弹窗, 详情 -->
        <detailWindow v-if="detailVisible" ref="detailWindow" @refreshDataList="getDataList"></detailWindow>
    </div>
</template>

<script>
import { getList } from "@/api/dataflow/ofiComplaint.js";
import assigned from './assigned.vue'
import detailWindow from './detail.vue';

export default {
    data() {
        return {
            dataListLoading: false,
            assignedVisible: false,
            detailVisible:false,
            dataForm: {
                time: [],
                regionId: '',
                startTime: '',
                endTime: '',
                stateValue: '', //投诉状态
                grade: '' //投诉等级
            },
            dayStates: [{
                label: '未处理',
                value: '0'
            },
            {
                label: '已处理',
                value: '1'
            },
            {
                label: '已下发',
                value: '2'
            }
            ],
            daygrade: [{
                label: '一般',
                value: '1'
            },
            {
                label: '严重',
                value: '2'
            }
            ],
            dataList: [],
            totalCount: 0,
            pageIndex: 1,
            pageSize: 10,
            dataListSelections: [],
            currentUserId: '',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近六个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一年',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },

    created() {
        this.currentUserId = this.$store.state.user.id
        console.log(this.currentUserId)
    },

    components: {
        assigned,detailWindow
    },

    activated() {
        let pageIndex = this.$route.query.pageIndex;
        if (pageIndex) {
            this.pageIndex = parseInt(pageIndex);
        }
        let pageSize = this.$route.query.pageSize;
        if (pageSize) {
            this.pageSize = parseInt(pageSize)
        }
        this.getDataList();
    },
    computed: {
        regionId: {
            get() {
                const newregionId = this.$store.state.user.regionId;
                console.log("regionId=====>" + newregionId)
                if (newregionId !== undefined) {
                    return Number(newregionId);
                } else {
                    return ''
                }
            }
        },
        gridId: {
            get() {
                const newgridId = this.$store.state.user.gridId;
                console.log("gridId=====>" + newgridId)
                if (newgridId !== undefined) {
                    return Number(newgridId);
                } else {
                    return ''
                }
            }
        },
        regionCode: {
            get() {
                console.log("regionCode=====>" + this.$store.state.user.regionCode)
                return this.$store.state.user.regionCode;
            }
        },
        gridCode: {
            get() {
                console.log("gridCode=====>" + this.$store.state.user.gridCode)
                return this.$store.state.user.gridCode;
            }
        },
    },

    methods: {
        //时间格式化
        gridDateFormatter(row, column, cellValue, index) {
            const daterc = row[column.property];
            if (daterc) {
                const dateMat = new Date(daterc);
                const Y = dateMat.getFullYear() + "-";
                const M =
                    dateMat.getMonth() + 1 < 10
                        ? "0" + (dateMat.getMonth() + 1) + "-"
                        : dateMat.getMonth() + 1 + "-";
                const D =
                    dateMat.getDate() < 10
                        ? "0" + dateMat.getDate() + " "
                        : dateMat.getDate() + " ";

                const H =
                    dateMat.getHours() < 10
                        ? "0" + dateMat.getHours() + ":"
                        : dateMat.getHours() + ":";

                const F =
                    dateMat.getMinutes() < 10
                        ? "0" + dateMat.getMinutes() + ":"
                        : dateMat.getMinutes() + ":";

                const S =
                    dateMat.getSeconds() < 10
                        ? "0" + dateMat.getSeconds() + ":"
                        : dateMat.getSeconds() + "";

                // return Y + M + D + H + F + S;
                return Y + M + D;
            }
        },

        // 指派投诉
        assignedComplaint(id) {
            var ids = id ? [id] : this.dataListSelections.map(item => {
                return item.id
            });
            this.assignedVisible = true
            this.$nextTick(() => {
                this.$refs.assigned.init(ids)
            })
        },

        //查询获取数据列表
        getDataList() {
            const _this = this;
            this.dataListLoading = true;
            const params_ = {
                'dayDateStart': _this.dataForm.startTime,
                'dayDateEnd': _this.dataForm.endTime,
                'area': _this.dataForm.regionId ? _this.dataForm.regionId : (_this.gridId !== '' ? _this.gridId : _this.regionId),
                'state': _this.dataForm.stateValue,
                'grade': _this.dataForm.grade,
                'page': _this.pageIndex,
                'limit': _this.pageSize,
            }
            getList(params_).then(({ data }) => {
                if (data && data.code === 0) {
                    _this.dataList = data.data.list
                    // console.log("this.dataList=>" + JSON.stringify(_this.dataList))
                    _this.totalCount = data.data.totalCount
                } else {
                    _this.dataList = []
                    _this.totalCount = 0
                    _this.$message.error(data.msg)
                }
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
                _this.dataListLoading = false;
            })
        },
        search() {
            this.pageIndex = 1;
            this.getDataList();
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
            this.refresh()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
            this.refresh()
        },
        //多选
        selectionChangeHandle(val) {
            this.dataListSelections = val
            console.log("dataListSelections==>" + this.dataListSelections)
        },
        refresh() {
        },

        changeHandler(val) {
            this.dataForm.time = val;
            if (this.dataForm.time != null) {
                this.dataForm.startTime = this.dealdate(this.dataForm.time[0]);
                this.dataForm.endTime = this.dealdate(this.dataForm.time[1]);
            } else {
                this.dataForm.startTime = '';
                this.dataForm.endTime = '';
            }
        },
        getDayStateValue(val) {
            this.dataForm.dayStateValue = val;
        },
        // 日期转换
        dealdate(date) {
            const dt = new Date(date)
            const y = dt.getFullYear()
            const m = (dt.getMonth() + 1 + '').padStart(2, '0')
            const d = (dt.getDate() + '').padStart(2, '0')
            return `${y}-${m}-${d}`
        },

        // 详情
        detailInfo(row) {
            this.detailVisible = true
            this.$nextTick(() => {
                this.$refs.detailWindow.init(row)
            })
        },

    }
}
</script>

<style scoped>
.box {
    width: 100%;
    height: 100%;
}

.coverClass {
    margin-top: 40px;
    width: 100%;
    height: 400px;

}

.stateClass {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
}

.b-sousuo {
    display: flex;
}

.el-table th > .cell {
    white-space: nowrap; width: fit-content;
}


/* .block{
    margin-right: 10px;
  } */
</style>
